nav {
    display: flex;
    flex-direction: column;
    position: sticky;
}

nav ul {
    list-style-type: none;
    text-align: left;
    padding-left: 0px;
}

nav ul li p {
    display: flex;
    justify-content: left;
    align-items: center;
    color: white;
    opacity: 0.5;
    padding-left: 10px;
    padding-bottom: 5px;
    margin-right: 20px;
    height: 30px;
}

nav ul li p:hover {
    color: #faf2da;
    opacity: 1;
}

nav ul li p.active {
    color: #faf2da;
    opacity: 1;
}

nav ul li a {
    color: inherit;
}

@media(min-width: 320px) {
    nav {
        position:sticky;
    }
    nav ul {
        list-style-type: none;
        text-align: left;
        padding-left: 0px;
        display: flex;
        flex-direction: row;
    }

    nav ul li p {
        padding: 0;
        transition: border-bottom 0.3s ease; /* Smooth transition for the color change */
        transition: color 0.3s ease;
    }
    
    nav ul li p:hover {
        border-bottom: 2.5px; /* Change the color when hovered over */
        color: #faf2da;
        opacity: 1;
    }
    
    nav ul li p.active {
        color: #faf2da;
        border-bottom: 0.15rem solid #faf2da;
        opacity: 1;
    }


}

@media(min-width: 1024px) {
    nav ul {
        display:block;
        list-style-type: none;
        text-align: left;
        padding-left: 0px;
    }

    nav ul li p {
        padding-left: 10px;
        transition: border-left 0.3s ease; /* Smooth transition for the color change */
        transition: color 0.3s ease;
        border-bottom: none;
    }
    
    nav ul li p:hover {
        border-left: 2.5px; /* Change the color when hovered over */
        color: #faf2da;
        opacity: 1;
        border-bottom: none;
    }
    
    nav ul li p.active {
        color: #faf2da;
        border-left: 0.15rem solid #faf2da;
        border-bottom: none;
        opacity: 1;
    }
}
