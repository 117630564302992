.links {
   margin-left: 0.25rem;
   margin-top: 2rem;
   display: flex;
   align-items: center;
}

.icon {
    width: 25px;
    height: 25px;
    margin-right: 25px;
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

a {
    color: inherit;
}

a:hover .icon {
    opacity: 1;
}