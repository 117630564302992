
.header {
    position: sticky;
}

h1 {
    color: white;
    margin: 0;
    font-size: 4rem;
    font-weight:lighter;
}

h2 {
    font-size: 1.5rem;
    margin: 0;
    margin-top: 0.75rem;
    color: white;
    font-weight:lighter;
}

.header > p {
    margin: 0;
    margin-top: 1rem;
    font-size: 1rem;
}