.main-component {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  padding-left: 6rem;
  padding-right: 6rem;
  background: #021526;
  font-family: "Lullaby Free Regular", sans-serif;
  color: white;
}

.left-component {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: sticky;
  padding-top: 6rem;
  padding-bottom: 6rem;
  width: 50%;
  top: 0;
  height: inherit;
  height: 100vh;
  transition: top 0.3s ease; /* Smooth transition for hiding the header */
}

.right-component {
  width: 50%;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

#experience,
#projects {
  padding-top: 100px;
}

.normal {
  opacity: 0.5;
}

.hightlight {
  opacity: 1;
}

.mobile-nav {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    background: #021526;

  }
  
  .nav-bar.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .nav-bar.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .hide-header .header {
    display: none;
  }

  .section {
    padding-top: 60px; /* Adjust this value to match the height of your navbar */
  scroll-margin-top: 60px;
  }

@media (min-width: 320px) {
  .main-component {
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .left-component {
    position: static;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    height: auto;
    width: 100%;
  }

  .right-component {
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .main-component {
    flex-direction: row;
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .left-component {
    position: sticky;
    padding-top: 6rem;
    padding-bottom: 6rem;
    height: 100vh;
    width: 50%;
  }

  .right-component {
    width: 50%;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}