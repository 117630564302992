.tag-flex-container {
    display: flex;
    color: #faf2da;;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 0px 10px;
    margin: 4px;
    border-radius: 16px;
    width: max-content;
    height:30px;
    align-items: center;
    font-size: 0.7rem;
}