body {
  margin: 0;
  background: #021526;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.logo {
  border-radius: 50%;
}